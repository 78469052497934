import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useResizeObserver from 'use-resize-observer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { FiDownload, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RouteComponentProps } from 'react-router-dom';

import './Sample.css';

import type { PDFDocumentProxy } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

type PDFFile = string | File | null;

interface MatchParams {
    pdfName: string;
}

interface PDFViewerProps extends RouteComponentProps<MatchParams> {
}

export default function PDFViewer({ match }: PDFViewerProps) {
    const pdfName = match.params.pdfName;
    const [file, setFile] = useState<PDFFile>(`./${pdfName}.pdf`);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        setFile(`./${pdfName}.pdf`);
    }, [pdfName]);

    function onFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const { files } = event.target;

        if (files && files[0]) {
            setFile(files[0] || null);
        }
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    function handlePageChange(newPageNumber: number): void {
        setPageNumber(newPageNumber);
    }

    function handleDownloadClick(): void {
        const link = document.createElement('a');
        link.href = file as string;
        link.download = 'downloaded.pdf';
        link.click();
    }

    return (
        <div className="Example">
            <div className="header">
                <div className="header-left">
                    <img className="logo" src="./logo.jpg" alt="Logo" />
                </div>
                <button className="download-button" onClick={handleDownloadClick}>
                    <FiDownload size={20} style={{ marginRight: '10px' }} />
                    Download
                </button>
            </div>
            <div ref={ref}>
                <div className="PDFContainer">
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        <Page pageNumber={pageNumber} height={height - 60 || undefined} renderAnnotationLayer={false} />
                    </Document>
                    <div className="page-navigation">
                        <button
                            onClick={() => handlePageChange(pageNumber - 1)}
                            disabled={pageNumber === 1}
                        >
                            <FiArrowLeft size={16} />
                        </button>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                        <button
                            onClick={() => handlePageChange(pageNumber + 1)}
                            disabled={pageNumber === numPages}
                        >
                            <FiArrowRight size={16} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
