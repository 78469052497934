import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import PDFViewer from './PDFViewer';
import { RouteComponentProps } from 'react-router-dom';

interface MatchParams {
    pdfName: string;
}

interface PDFViewerProps extends RouteComponentProps<MatchParams> {
    history: any;
    location: any;
}

function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route
                        path="/:pdfName"
                        render={(props: RouteComponentProps<MatchParams>) => (
                            <PDFViewer {...props} history={props.history} location={props.location} />
                        )}
                    />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
